import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Helmet from '../components/Helmet'
import AltBio from '../components/AltBio'
import Header from '../components/Header'
import Container from '../components/Container'
import Posts from '../components/Posts'

import typography from '../utils/typography'
import media from '../utils/media'

const PageTitle = styled.h1`
  line-height: 1.3;
  font-size: 2rem;
  margin: ${typography.rhythm(0.5)} 0 ${typography.rhythm(1)} 0;
  text-align: center;

  ${media.desktop`
    margin: ${typography.rhythm(0.75)} 0 ${typography.rhythm(1.5)} 0;
  `}
`

class BlogPostTemplate extends React.Component {
  render () {
    const { tag } = this.props.pathContext
    const posts = _.get(this, 'props.data.allMarkdownRemark.edges')

    const title = `${_.capitalize(tag)} Cocktails`
    const firstImage = _.get(posts, '0.node.frontmatter.image.childImageSharp.fluid')

    return (
      <div>
        <Helmet
          title={title}
          image={firstImage}
          siteMetadata={this.props.data.site.siteMetadata} />
        <Header />

        <Container>
          <PageTitle>{title}</PageTitle>
        </Container>

        <Container size="wide">
          <Posts posts={posts} />
        </Container>

        <Container>
          <AltBio style={{ textAlign: 'center' }} />
        </Container>
      </div>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      ...HelmetFragment
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          ...PostFragment
          ...PostThumbFragment
        }
      }
    }
  }
`
